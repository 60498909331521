import React from 'react';
import { Helmet } from 'react-helmet';
import SmartBanner from 'react-smartbanner';

import 'react-smartbanner/dist/main.css';

const title = 'Hire Professional Freelancers for Your Business | TERAWORK';
const description =
  'Find top-tier freelance services, from SEO writers to app developers. Post a job and hire vetted professionals at TERAWORK. Get proven talents in minutes.';
const url = 'https://www.terawork.com/freelance';
const image = 'https://www.terawork.com/images/favicons/mstile-150x150.png';
const sitename = 'TERAWORK';

function HomeSeo() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="url" content={url} />
        <meta property="og:title" typeof="string" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />
        <meta property="og:site_name" content={sitename} />
        <meta property="og:type" content="website" />
        <meta property="twitter:type" content="website" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:image" content={image} />
      </Helmet>
      <SmartBanner title={sitename} />
    </>
  );
}

export default HomeSeo;
